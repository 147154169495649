import React from "react";

import Layout from "../../components/Layout/layout";
import SEO from "../../components/SEO/seo";
import AirportTransportation from "../../components/Airport-transportation/index";

const AirportTransport = () => (
	<Layout>
		<SEO
			title="Taxi Flughafen Hamburg | Taxi Neumünster zum Hamburger "
			meta="Taxi Kiel to Hamburg Airport | Taxi Neumünster to Hamburg Airport"
			description="Buchen Sie Ihre Taxifahrt für ein besseres Reiseerlebnis vom Hamburger Flughafen zu Ihrem Ziel zu einem günstigen Preis."
		/>
		<AirportTransportation />
	</Layout>
);

export default AirportTransport;
